<div class="row">
    <div class="col-lg-12">
        <br/>
        <!-- TODO: maybe we can put something useful here? -->
    </div>
</div>

<div class="row exchanges-frame">
  <div *ngFor="let exchange of exchangeList" class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
    <nebular-exchange-thumbnail [exchange]="exchange"></nebular-exchange-thumbnail>
  </div>
</div>