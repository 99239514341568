<br/>
<div class="row paddedRow">
    <div class="col-lg-12">
        <h4>Manage shortlist of assets to be used on the Exchanges.</h4>
    </div>
</div>

<div *ngIf="this.nebularService.CookieAgreement" class="row paddedRow" style="margin-bottom: 60px;">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <nebular-custom-asset-wizard (assetAdded)="customAssets.highlightLastAddedAsset($event)"
            (addAssetFailed)="customAssets.highlightDuplicateAsset($event)">
        </nebular-custom-asset-wizard>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
        <nebular-custom-asset-list #customAssets></nebular-custom-asset-list>
    </div>
</div>

<nebular-cookie-consent *ngIf="!this.nebularService.CookieAgreement"></nebular-cookie-consent>
