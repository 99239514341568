import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nebular-exchange-analytics',
  templateUrl: './exchange-analytics.component.html',
  styleUrls: ['./exchange-analytics.component.css']
})
export class ExchangeAnalyticsComponent implements OnInit {

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {
    //TODO
  }
}
