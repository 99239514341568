<div *ngIf="this.nebularService.CookieAgreement" class="row exchanges-frame">
    <ng-container *ngFor="let exch of exchanges">
        <nebular-custom-exchange [exchange]="exch"></nebular-custom-exchange>
    </ng-container>
    
    <div *ngIf="!this.uiService.DraggingExchange" id="addCustomExchange" class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
        <div id="addExchangeButton" class="exchange-link" (click)="addCustomExchange();">
            (add)
        </div>
    </div>
</div>


<div id="draggedSnapshot" style="position: fixed; z-index: 5;">
    &nbsp;
</div>

<nebular-cookie-consent *ngIf="!this.nebularService.CookieAgreement"></nebular-cookie-consent>
