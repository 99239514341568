
<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <h4 *ngIf="trades.size <= 0">Waiting for trade stream...</h4>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 duration-header">
        <h4>Currencies traded in past {{duration}}:</h4>
    </div>
</div>
<div class="row">
    <div class="trade-stream col-lg-6 col-md-6 col-sm-12">
        <div id="trade-stream-divider"></div>
        <div *ngFor="let item of trades" class="trade-item highlight {{item.isEven ? '' : 'odd'}}">
            {{item.actionName }}
            <a class="link inline-link" href="{{item.linkHref}}" target="exchTab">{{item.linkText}}</a>
            {{item.note}}
        </div>
    </div>
    <div class="assets-stats-panel col-lg-6 col-md-6 col-sm-12">
        <table id="assetStatisticsTable" matSort (matSortChange)="sortStatistics($event)">
            <tr>
              <th mat-sort-header="asset" colspan="2">Currency</th>
              <th mat-sort-header="trades">Trades</th>
              <th mat-sort-header="volume">Volume</th>
            </tr>
          
            <tr *ngFor="let stat of sortedStatistics">
              <td><img class="asset-icon" src="{{stat.assetIcon}}" title="{{stat.assetCode}}"/></td>
              <td>{{stat.assetTitle}}</td>
              <td>{{stat.numTrades}}</td>
              <td>
                  {{Utils.formatAmount(stat.volume)}} {{stat.assetCode}}
                  <span *ngIf="stat.volumeInNative > 0">(~{{Utils.formatAmount(stat.volumeInNative)}} XLM)</span>
              </td>
            </tr>
        </table>
    </div>
</div>